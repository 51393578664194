.logo {
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-header {
  background: #9c4fff;
}

[data-braintree-id='methods-label'], [data-braintree-id='methods-edit'], [data-braintree-id='choose-a-way-to-pay'], [data-braintree-id="other-ways-to-pay"] {
  color: rgba(237, 242, 247);
}

[data-braintree-id="wrapper"] div {
  font-family: Play, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.loader {
  border-top-color: #3498db !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}